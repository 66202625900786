import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home'
import Editor from './Pages/Editor360';
import Viewer from './Pages/Viewer';
import Login from './Pages/Login';
function App() {
  return (
      <div className='App'>
        <Viewer/>
        
      </div>
  );
}

export default App;
