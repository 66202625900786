import './Viewer.css';
import { useState, useEffect } from 'react';
import axios from 'axios';

import logo from './MES2.png'

function Viewer() {
  const [captures, setCaptures] = useState([])
  const [width, setWidth] = useState('0%')
  const [selectedOption, setSelectedOption] = useState('https://www.mes-design.com');
  // const [link, setLink] = useState('https://www.mes-design.com')
  const [projectName, setProjectName] = useState('Project Name')
  const queryParameters = new URLSearchParams(window.location.search)
  const projectId =  queryParameters.getAll("projectId")[0]
  console.log(projectId)
  function openFunction(){
    setWidth('100%');
  }
  function closeFunction(){
    setWidth('0%');
  }
  useEffect(() => {
    // Function to fetch projects data from the API
    const fetchProjectName = async () => {
      try {
        const response = await axios.get(`https://nodejs.mes-design.com/api/getProject.php?projectId=${projectId}`);
        console.log(response.data)
        setProjectName(response.data[0].Name)
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    // Function to fetch projects data from the API
    const fetchCaptures = async () => {
      try {
        const response = await axios.get(`https://nodejs.mes-design.com/api/getCaptures.php?projectId=${projectId}`);
        console.log(response.data)
        setCaptures(response.data)
        setSelectedOption(response.data[response.data.length-1].iframe_link)
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjectName(); // Call the function to fetch projects when the component mounts
    fetchCaptures();
  }, []); // Empty dependency array to ensure the effect runs only once
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="Viewer">
      {/* <!--start desktop navbar--> */}
        <nav id="nv" className="main nav">
            <div className="logo">
            <a href="https://www.mes-design.com/index.html">
                <img src={logo} alt=""></img>
            </a> 
            <p><span><span className="M">M</span>odern</span> <br /> Engineering Services</p>
            </div>
            <a href="https://www.mes-design.com/about.html">ABOUT US</a>
            <a href="https://www.mes-design.com/bim.html">BIM</a>
            <a href="https://www.mes-design.com/eng design.html">ENGINEERING DESIGN</a>
            <a href="https://www.mes-design.com/eng solutions.html">ENGINEERING SERVICES</a>
            <a href="https://www.mes-design.com/previous.html">PREVIOUS PROJECTS</a>
            <a className="a-con" href="https://www.mes-design.com/contact.html">Contact Us</a>
        </nav>
        {/* <!--end desktop navbar--> */}
        {/* <!--start mobile navbar--> */}
        <a className='logo-mobile' href="https://www.mes-design.com/index.html">
                <img src={logo} alt=""></img>
        </a> 
        <div id="mainbox" onClick={openFunction}>&#9776;</div>
        <div id="menu" className="sidemenu" style={{width:width}}>
            <a style={{color: '#2DB9F5'}} href="https://www.mes-design.com/index.html">HOME</a>
            <a href="https://www.mes-design.com/about.html">ABOUT US</a>
            <a href="https://www.mes-design.com/bim.html">BIM</a>
            <a href="https://www.mes-design.com/eng design.html">ENGINEERING DESIGN</a>
            <a href="https://www.mes-design.com/eng solutions.html">ENGINEERING SERVICES</a>
            <a href="https://www.mes-design.com/previous.html">PREVIOUS PROJECTS</a>
            <a className="a" href="https://www.mes-design.com/contact.html">Contact Us</a>
            <a href="#" className="closebtn" onClick={closeFunction}>&times;</a>
        </div>
        {/* end mobile navbar */}
        <div className='mainCont'>
          <p className='prName'>{projectName}</p>
          <label className='input-label' htmlFor="options">Choose capture date:</label>
          <select id="options" value={selectedOption} onChange={handleChange}>
            {/* <option value="">--Please choose an option--</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option> */}
            {captures.map(capture => (
                <option key={capture.iframe_link} value={capture.iframe_link}>{capture.Date}</option>
            ))}
          </select>
          <iframe title='projectViewer' className='iframe' src={selectedOption}></iframe>
        </div>
        
    </div>
  );
}

export default Viewer;
